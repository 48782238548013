import { type ErrorInfo } from "react";

const SAMPLE_RATE = parseFloat(process.env.NEXT_PUBLIC_ERROR_SAMPLE_RATE);
const headers = { "Content-Type": "application/json" };
const method = "POST";

const stringify = (stack?: null | string) => {
  if (!stack) {
    return;
  }

  return stack
    .split("\n")
    .filter(Boolean)
    .slice(0, 10)
    .map((line) => line.replace(window.origin, ""));
};

export async function beaconError(id: string, error: Error, info?: ErrorInfo) {
  if (typeof window === "undefined") {
    return; // don't beacon for server
  }

  if (Math.random() > SAMPLE_RATE) {
    return; // adjust NEXT_PUBLIC_ERROR_SAMPLE_RATE to control beaconing frequency
  }

  const Bowser = await import("bowser").then((mod) => mod.default);
  const { message, name, ...rest } = error;
  const body: any = {
    ...Bowser.parse(navigator.userAgent),
    componentStack: stringify(info?.componentStack),
    id,
    message,
    name,
    rest,
    stack: stringify(error.stack),
    url: location.href,
  };

  await fetch("/_yc/beacon", { body: JSON.stringify(body), headers, method });
}

export async function beacon(id: string, message: string) {
  if (typeof window === "undefined") {
    return; // don't beacon for server
  }

  if (Math.random() > SAMPLE_RATE) {
    return; // adjust NEXT_PUBLIC_ERROR_SAMPLE_RATE to control beaconing frequency
  }

  const Bowser = await import("bowser").then((mod) => mod.default);
  const body: any = {
    ...Bowser.parse(navigator.userAgent),
    id,
    message,
    url: location.href,
  };
  await fetch("/_yc/beacon", {
    body: JSON.stringify(body),
    headers,
    method,
  });
}
