import { sortObj } from "@yahoo-news/util";
import { type FC, type PropsWithChildren } from "react";

export const Body: FC<PropsWithChildren> = ({ children }) => (
  <div className="h-screen overflow-y-scroll border border-solid border-black bg-white text-black">
    {children}
  </div>
);

export const Button: FC<PropsWithChildren> = ({ children }) => {
  return (
    <button className="m-2 rounded border border-solid border-[#400090] bg-white px-2 py-1 font-semibold capitalize text-[#6633a6]">
      {children}
    </button>
  );
};

export const Json: FC<{ data: Record<string, any> }> = ({ data }) => (
  <pre>{JSON.stringify(sortObj(data), null, 2)}</pre>
);
