"use client";
import { usePathname } from "next/navigation";
import { createContext, useEffect, useState, useContext } from "react";
import { useSeamlessSlugChange } from "@/hooks/events";

type PathContextState = string[];

const PathContext = createContext<PathContextState>([]);

export const PathProvider = ({ children }: { children: React.ReactNode }) => {
  const path = usePathname();
  const [paths, setPaths] = useState<PathContextState>([path]); // [key: string]: string
  const slugState = useSeamlessSlugChange(null);

  useEffect(() => {
    let newPath = slugState?.slug || path;

    // strip off any query params
    const searchIndex = newPath.indexOf("?");
    if (searchIndex !== -1) {
      newPath = newPath.substring(0, searchIndex);
    }

    setPaths((prev) => {
      if (prev.length < 1 || prev[prev.length - 1] !== newPath) {
        return [...prev, newPath];
      } else {
        return prev;
      }
    });
  }, [path, slugState?.slug]);

  return <PathContext.Provider value={paths}>{children}</PathContext.Provider>;
};

export const useCurrentPath = () => {
  const paths = useContext(PathContext);
  return paths.length ? paths[paths.length - 1] : null;
};

export const usePreviousPath = () => {
  const paths = useContext(PathContext);
  return paths.length > 1 ? paths[paths.length - 2] : null;
};

export const useIsClientSideNavigation = () => {
  const paths = useContext(PathContext);
  return paths.length > 1;
};

export const useIsModal = () => {
  const paths = useContext(PathContext);
  return (
    paths.length > 1 &&
    paths[0].indexOf(".html") === -1 &&
    paths[paths.length - 1].indexOf(".html") !== -1
  );
};
