import { SCRIPT_ID } from "@yahoo-creators/edge/lib/constants";
import { useEffect } from "react";
import { beaconError } from "@/lib/beacon";

const ALLOWLIST = new Set(["https://creators.yahoo.com"]);

/**
 * This hook is used to detect ESI failures and beacon an error if it happens.
 * It will only run on production hosts (ie https://www.yahoo.com).
 * This list may need to be amended in the future if we support additional hosts
 * eg https://creators.yahoo.com, https://de.nachrichten.yahoo.com/, etc.
 */
export const useEsiCanary = () => {
  useEffect(() => {
    if (!ALLOWLIST.has(location.origin)) {
      return;
    }

    const el = document.getElementById(SCRIPT_ID);
    const innerHTML = el?.innerHTML;
    const success = innerHTML?.indexOf("<esi:vars>") === -1;
    if (success) {
      return;
    }

    const error: any = new Error("ESI failure");
    error.innerHTML = innerHTML;
    delete error.stack; // the stack trace is not useful in this case
    beaconError("esi-canary", error);
  }, []);

  return null;
};
