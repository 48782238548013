"use client";

import {
  ClientSamplingTracerPlugin,
  DocumentLoadTracerPlugin,
  Tracer,
} from "@vzmi/otel-browser";
import { once } from "@yahoo-news/util";
import { type FC, useEffect } from "react";
import { type Config, useConfigContext } from "@/contexts/ConfigContext";

const initializeOtel = once(({ samplingRate }: Config["otel"]) => {
  // TODO: Otel should support relative URLs for collectorEndpoint
  const origin = window.location.origin;

  const _tracer = new Tracer({
    collectorEndpoint: new URL("/_yc/api/telemetry", origin).toString(),
    namingStandards: {
      customNamespace: "yc",
    },
    plugins: [
      [ClientSamplingTracerPlugin, { samplingRate }],
      DocumentLoadTracerPlugin,
    ],
    serviceName: "creators.next.browser",
  });
});

export const OtelBrowser: FC = () => {
  const config = useConfigContext();

  useEffect(() => {
    initializeOtel(config.otel);
  }, [config.otel]);

  return null;
};
