"use client";

import {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState,
  type FC,
  type ReactNode,
} from "react";
import {
  type NcpCacheContextType,
  CACHE_ACTIONS,
  type NCPStreamData,
} from "./types";
import { initialStreamState, reducer } from "./utils";

export const NcpCacheContext = createContext<NcpCacheContextType<any>>({
  articlePosition: {
    ntkCard: 0,
    stream: "",
  },
  cacheStream: async () => {},
  cachedStreams: {
    main: { ...initialStreamState },
    ntk: { ...initialStreamState },
    relatedContentStream: { ...initialStreamState },
    trendingStoryStream: { ...initialStreamState },
  },
  clearStreamCache: async () => {},
  setArticlePosition: () => {},
  setStreamError: async () => {},
});

interface Props {
  children: ReactNode;
}

export const NcpCacheProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { cachedStreams: {} });

  const [articlePosition, setArticlePosition] = useState<{
    ntkCard: number;
    stream: string;
  }>({
    ntkCard: 0,
    stream: "",
  });

  const cacheStream = useCallback(
    (data: NCPStreamData<any>, cacheKey: string) => {
      dispatch({
        payload: { cacheKey, data },
        type: CACHE_ACTIONS.CACHE,
      });
    },
    [],
  );

  const clearStreamCache = useCallback((cacheKey: string) => {
    dispatch({
      payload: { cacheKey },
      type: CACHE_ACTIONS.CACHE_CLEAR,
    });
  }, []);

  const setStreamError = useCallback((error: any, cacheKey: string) => {
    dispatch({
      payload: { cacheKey, error },
      type: CACHE_ACTIONS.CACHE_ERROR,
    });
  }, []);

  return (
    <NcpCacheContext.Provider
      value={{
        articlePosition,
        cacheStream,
        cachedStreams: state.cachedStreams,
        clearStreamCache,
        setArticlePosition,
        setStreamError,
      }}
    >
      {children}
    </NcpCacheContext.Provider>
  );
};

export const useNcpCacheContext = () => useContext(NcpCacheContext);
