import {
  Children,
  type ReactNode,
  type FC,
  type PropsWithChildren,
} from "react";

interface Props {
  label: ReactNode;
}

export const TabItem: FC<PropsWithChildren<Props>> = ({ children, label }) => {
  return (
    <div>
      {label}
      <div className="absolute left-0 z-50 hidden w-full group-hover:block">
        {children}
      </div>
    </div>
  );
};

export const TabList: FC<PropsWithChildren> = ({ children }) => {
  const tabs = Children.toArray(children);

  return (
    <ul className="flex">
      {tabs.map((tab, index) => (
        <li key={index} className="group">
          {tab}
        </li>
      ))}
    </ul>
  );
};
