/**
 * This file is required to make the library work with Next.js.
 * We include a library which uses the context provider directly
 * into a server component with an intermediate shim component.
 */

"use client";

import { IntlProvider } from "react-intl";
export { IntlProvider };
