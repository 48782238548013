import {
  CACHE_ACTIONS,
  type CacheAction,
  STREAM_LOADING_STATES,
  type State,
  type NCPStreamData,
} from "./types";

export const initialStreamState: NCPStreamData<any> = {
  currentPage: 0,
  items: [],
  loadingState: STREAM_LOADING_STATES.READY,
  nextPage: true,
  nextPaginationCursor: "",
};

/**
 * Responsible for updating NcpCacheContext state.
 */
export const reducer = (
  state: State<any>,
  action: {
    type: CacheAction;
    payload: {
      cacheKey: string;
      data?: NCPStreamData<any>;
      error?: any;
    };
  },
): State<any> => {
  switch (action.type) {
    case CACHE_ACTIONS.CACHE: {
      const { cacheKey, data } = action.payload;

      return {
        ...state,
        cachedStreams: {
          ...state.cachedStreams,
          [cacheKey]: {
            ...state.cachedStreams[cacheKey],
            currentPage: (state.cachedStreams[cacheKey]?.currentPage || 0) + 1,
            error: undefined,
            items: [
              ...(state.cachedStreams[cacheKey]?.items || []),
              ...(data?.items || []),
            ],
            loadingState: data?.nextPage
              ? STREAM_LOADING_STATES.READY
              : STREAM_LOADING_STATES.FINISHED,
            nextPaginationCursor: data?.nextPaginationCursor,
          },
        },
      };
    }
    case CACHE_ACTIONS.CACHE_CLEAR: {
      const { cacheKey } = action.payload;
      return {
        ...state,
        cachedStreams: {
          ...state.cachedStreams,
          [cacheKey]: {
            ...initialStreamState,
          },
        },
      };
    }
    case CACHE_ACTIONS.CACHE_ERROR: {
      const { cacheKey, error } = action.payload;
      return {
        ...state,
        cachedStreams: {
          ...state.cachedStreams,
          [cacheKey]: {
            ...state.cachedStreams[cacheKey],
            error,
            items: state.cachedStreams[cacheKey]?.items || [],
            loadingState: STREAM_LOADING_STATES.ERROR,
          },
        },
      };
    }
    default:
      return state;
  }
};
