export interface NcpCacheContextType<ItemType> extends State<ItemType> {
  cacheStream: (data: NCPStreamData<ItemType>, cacheKey: string) => void;
  clearStreamCache: (cacheKey: string) => void;
  setStreamError: (error: any, cacheKey: string) => void;
  articlePosition: Object & {
    ntkCard: number;
    stream: string;
  };
  setArticlePosition: (value: { ntkCard: number; stream: string }) => void;
}

export interface NCPStreamData<ItemType> {
  currentPage?: number;
  error?: any;
  items: ItemType[];
  loadingState?: LoadingState;
  nextPage?: boolean;
  nextPaginationCursor?: string;
}

// all possible actions for a stream cache
export const CACHE_ACTIONS = Object.freeze({
  CACHE: "CACHE",
  CACHE_CLEAR: "CACHE_CLEAR",
  CACHE_ERROR: "CACHE_ERROR",
});

// all possible loading states for a stream
export const STREAM_LOADING_STATES = Object.freeze({
  ERROR: "ERROR",
  FINISHED: "FINISHED",
  READY: "READY",
});

type CacheActions = keyof typeof CACHE_ACTIONS;
export type CacheAction = (typeof CACHE_ACTIONS)[CacheActions];

type LoadingStates = keyof typeof STREAM_LOADING_STATES;
export type LoadingState = (typeof STREAM_LOADING_STATES)[LoadingStates];

export type State<ItemType> = {
  cachedStreams: {
    [key: string]: NCPStreamData<ItemType>;
  };
};
