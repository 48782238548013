import(/* webpackMode: "eager" */ "/app/apps/content/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["EsiCanary"] */ "/app/apps/content/src/components/common/EsiCanary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OtelBrowser"] */ "/app/apps/content/src/components/common/OtelBrowser.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/content/src/components/common/Perf/DebugBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "/app/apps/content/src/contexts/ConfigContext/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NcpCacheProvider"] */ "/app/apps/content/src/contexts/NcpCacheContext/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PathProvider"] */ "/app/apps/content/src/contexts/PathProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RequestProvider"] */ "/app/apps/content/src/lib/request/client.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../font/YahooBeta-VF-Web.woff2\",\"variable\":\"--font-yahoo-beta\",\"weight\":\"200 900\"}],\"variableName\":\"yahooBeta\"}");
;
import(/* webpackMode: "eager", webpackExports: ["useEsiVars"] */ "/app/packages/edge/hooks/useEsiVars.ts");
;
import(/* webpackMode: "eager", webpackExports: ["IntlProvider"] */ "/app/packages/i18n/lib/shim.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useDeviceSize"] */ "/app/packages/util/hooks/useDeviceSize.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useIntersectionObserver","useInView","useStickyActive"] */ "/app/packages/util/hooks/useIntersectionObserver.ts");
;
import(/* webpackMode: "eager", webpackExports: ["detectBot"] */ "/app/packages/util/lib/client.ts");
